import axios from 'axios';

import { UPLOAD_API_URL } from 'app/utils/constants';

export const RECEIVE_UPLOAD = 'RECEIVE_UPLOAD';
export const DELETE_UPLOAD = 'DELETE_UPLOAD';

/**
 * Uploads a file to the Upload Service API
 *
 * @param {blob} file A blob from a file input.
 * @return {promise} A promise that resolves with the current conversations.
 */
export const createUpload = (file) => {
  return (dispatch, getState) => {
    const token = getState().session.coreServiceToken;
    const data = new FormData();

    data.append('upload', file);

    return axios
      .post(`${UPLOAD_API_URL}/uploads`, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        dispatch(receiveUpload(result.data.data));
        return result.data.data.attributes.url;
      });
  };
};

/**
 * Deletes a file from the Upload Service API
 *
 * @param {string} file A path to a remote file to delete.
 * @return {promise} A promise that resolves with the current conversations.
 */
export const destroyUpload = (file) => {
  return (dispatch, getState) => {
    const token = getState().session.coreServiceToken;
    const regex = new RegExp(
      /http(?:s|):\/\/(?:upload-service(?:-\w+|).carrumhealth.com|localhost:5050)/
    );

    if (!regex.test(file)) return;

    const id = file
      .replace(regex, '')
      .replace(/\/rails\/active_storage\/blobs\/(redirect\/|)/, '')
      .split('/')[0];

    return axios
      .delete(`${UPLOAD_API_URL}/uploads/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        dispatch(deleteUpload(file));
        return file;
      });
  };
};

export function receiveUpload(upload) {
  return {
    type: RECEIVE_UPLOAD,
    upload,
  };
}

export function deleteUpload(upload) {
  return {
    type: DELETE_UPLOAD,
    upload,
  };
}
