import axios from 'axios';
import { uniq } from 'lodash';

import { fetchFromService, fetchRecursively } from 'app/actions/commonActions';
import { showGlobalError } from 'app/actions/uiActions';
import { CORE_API_URL } from 'app/utils/constants';
import { unformatObject } from 'app/utils/reducerUtils';
import { fetchLocationFromAddress } from 'app/utils/methods';

import {
  fetchProvidersParams,
  fetchPossibleProvidersByProcedureParams,
} from './constants';

export const RECEIVE_PROVIDERS = 'RECEIVE_PROVIDERS';
export const RECEIVE_CLIENT_PROVIDERS = 'RECEIVE_CLIENT_PROVIDERS';
export const RECEIVE_PRICING_GROUPS = 'RECEIVE_PRICING_GROUPS';
export const RECEIVE_PROVIDER = 'RECEIVE_PROVIDER';
export const RECEIVE_PROVIDER_PROCEDURES = 'RECEIVE_PROVIDER_PROCEDURES';
export const RECEIVE_PROVIDER_LOADING = 'RECEIVE_PROVIDER_LOADING';
export const UPDATE_PROVIDER_ROLLUPS = 'UPDATE_PROVIDER_ROLLUPS';

const getPricingGroups = fetchFromService(`${CORE_API_URL}/pricing_groups`);
const getProviders = fetchFromService(`${CORE_API_URL}/hospitals`);
const getProvider = fetchFromService(`${CORE_API_URL}/hospitals/:id`);
const getProvidersByProcedure = fetchFromService(
  `${CORE_API_URL}/offered_procedures/:id/hospitals`
);

/**
 * Fetch all pricing groups from the Core Service.
 *
 * @return  {promise}  promise that resolves with pricing group data
 */
export const fetchPricingGroups = () => {
  return async function (dispatch) {
    const response = await dispatch(
      getPricingGroups(`${CORE_API_URL}/pricing_groups`)
    );

    await dispatch(receivePricingGroups(response.data.data));
  };
};

/**
 * Fetch all providers from the Core Service.
 *
 * @return  {promise}  promise that resolves with providers data
 */
export const fetchProviders = (params = {}) =>
  fetchRecursively(getProviders, receiveProviders, {
    ...fetchProvidersParams,
    ...params,
  });

/**
 * Fetch a provider from the Core Service with procedure relations included. If
 * an address param is present, fetch the coordinates from Google APIs.
 *
 * @param  {number}  providerId  identifier of hospital to fetch
 *
 * @return  {promise}            promise that resolves with provider data
 */
export function fetchProvider(providerId, params = {}) {
  return async function (dispatch, getState) {
    const {
      provider: { loading },
    } = getState();

    // Return early if already fetching.
    if (loading) return;

    const response = await dispatch(getProvider({ ...params, id: providerId }));

    if (!response?.data) return;

    await dispatch(receiveProvider(response.data.data));

    if (params.include?.includes('procedures')) {
      await dispatch(getProviderProcedures());
    }
  };
}

/**
 * Create a new provider in the API.
 *
 * @param   {object}   params  object used to create a new provider
 *
 * @return  {promise}          promise that resolves with provider data
 */
export function createProvider(params) {
  return function (dispatch, getState) {
    return fetchLocationFromAddress(params.address)
      .then(({ address, lat, lng }) =>
        axios
          .post(
            `${CORE_API_URL}/hospitals`,
            {
              hospital: unformatObject({
                ...params,
                address,
                latitude: lat,
                longitude: lng,
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${getState().session.coreServiceToken}`,
              },
            }
          )
          .then((provider) => dispatch(receiveProvider(provider.data.data)))
          .catch((error) => dispatch(showGlobalError(error)))
      )
      .catch(() => dispatch(showGlobalError('Invalid or incomplete address.')));
  };
}

/**
 * Update a provider in the API.
 *
 * @param   {number}   providerId  identifier of provider to update
 * @param   {object}   params      data used to update provider
 *
 * @return  {promise}              promise that resolves with provider data
 */
export function updateProvider(providerId, params) {
  return function (dispatch, getState) {
    dispatch(receiveProviderLoading(true));

    const promise = params.address
      ? fetchLocationFromAddress(params.address)
      : Promise.resolve({});
    return promise
      .then(({ address, lat, lng }) => {
        const hospital =
          address && lat && lng
            ? unformatObject({
                ...params,
                address,
                latitude: lat,
                longitude: lng,
              })
            : unformatObject(params);
        return axios
          .patch(
            `${CORE_API_URL}/hospitals/${providerId}`,
            { hospital, include: 'procedures,product_offering,rollups' },
            {
              headers: {
                Authorization: `Bearer ${getState().session.coreServiceToken}`,
              },
            }
          )
          .then((provider) => {
            return dispatch(receiveProvider(provider.data.data));
          })
          .catch((error) => dispatch(showGlobalError(error)));
      })
      .catch(() => dispatch(showGlobalError('Invalid or incomplete address.')));
  };
}

/**
 * Add a new procedure and physician association to a provider.
 *
 * @param   {number}   providerId   identifier of of the provider to update
 * @param   {number}   procedureId  identifier of of the procedure to associate
 * @param   {number}   physicianId  identifier of of the physician to associate
 *
 * @return  {promise}               promise that resolves with provider data
 */
export function addProviderProcedure(providerId, procedureId, physicianId) {
  return function (dispatch, getState) {
    dispatch(receiveProviderLoading(true));

    return axios
      .post(
        `${CORE_API_URL}/offered_procedures/${procedureId}/hospitals/${providerId}/physicians`,
        {
          physician: { id: physicianId },
        },
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      )
      .then(() => dispatch(getProviderProcedures(true)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Remove a procedure and physician association from provider.
 *
 * @param   {number}    providerId   identifier of of the provider to update
 * @param   {number}    procedureId  identifier of of the procedure to remove
 * @param   {number}    physicianId  identifier of of the physician to remove
 *
 * @return  {promise}                promise that resolves with provider data
 */
export function removeProviderProcedure(providerId, procedureId, physicianId) {
  return function (dispatch, getState) {
    dispatch(receiveProviderLoading(true));

    return axios
      .delete(
        `${CORE_API_URL}/offered_procedures/${procedureId}/hospitals/${providerId}/physicians/${physicianId}`,
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      )
      .then(() => dispatch(getProviderProcedures(true)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Get a sorted list of possible providers for a procedure based on the client
 * and geography.
 *
 * @param    {string}  clientId     identifier of client that employs patient
 * @param    {string}  procedureId  identifier of patient's requested procedure
 * @param    {string}  latitude     coordinate used to limit available results
 * @param    {string}  longitude    coordinate used to limit available results
 * @param    {string}  sort         method to sort response data
 *
 * @returns  {promise}              promise that resolves with a list of gold
 *                                  and silver providers and their product
 *                                  offerings, which perform the procedure and
 *                                  which the patient can travel to
 */
export const fetchPossibleProvidersByProcedure = ({
  clientId,
  latitude,
  longitude,
  procedureId,
  sort,
}) => {
  return async (dispatch, getState) => {
    const {
      episode: {
        episode: { location },
      },
      provider: { loading },
    } = getState();

    // Return early if we're already in the process of loading provider data to
    // prevent redundant API calls dispatched by hooks.
    if (loading) return;

    await dispatch(receiveProviderLoading(true));

    latitude = latitude || location?.lat;
    longitude = longitude || location?.lng;

    const params = {
      ...fetchPossibleProvidersByProcedureParams,
      client_id: clientId,
      sort,
    };

    // If both longitude and latitude are known, include them as filter params
    // for this API request. If not, prevent sorting by distance.
    if (latitude && longitude) {
      params['location[latitude]'] = latitude;
      params['location[longitude]'] = longitude;
    } else {
      params.sort = params.sort?.replace('distance', 'physicians.id');
    }

    await dispatch(
      fetchRecursively(getProvidersByProcedure, receiveClientProviders, {
        id: procedureId,
        ...params,
      })
    );

    dispatch(receiveProviderLoading(false));
  };
};

/**
 * Get a list of procedures associated with the providers that provide them and
 * physicians that offer each procedure for each provider.
 *
 * @param   {boolean}  resetCache  whether to drop cached provider procedures
 *                                 before fetching
 *
 * @return  {promise}              promise that resolves with list of
 *                                 procedures including providers for each
 */
export const getProviderProcedures =
  (resetCache = false) =>
  async (dispatch, getState) => {
    const {
      provider: { providerProcedures, provider },
      physician: { physicians },
      procedure: { procedures },
    } = getState();

    // Reset the cache (ex. when calling `removeProviderProcedure`).
    if (resetCache) {
      await dispatch(receiveProviderProcedures(null));
      await dispatch(fetchProvider(provider.id, { include: 'procedures' }));

      return;
    }

    // Return early if procedures for provider have already been computed
    if (providerProcedures[provider.id]) {
      return;
    }

    // Compose a dictionary of ONLY procedures that are offered by this
    // provider (hospital) and decorate each with a list of physicians that
    // provide the procedure at the provider.
    const _providerProcedures = {};

    provider.procedures.forEach(({ offeredProcedureId, physicianId }) => {
      _providerProcedures[offeredProcedureId] =
        _providerProcedures[offeredProcedureId] ||
        procedures.find(({ id }) => parseInt(id) === offeredProcedureId);

      _providerProcedures[offeredProcedureId].physicians = uniq([
        ...(_providerProcedures[offeredProcedureId]?.physicians || []),
        ...physicians.filter(({ id }) => parseInt(id) === physicianId),
      ]);
    });

    // Return the dictionary of decorated procedures as an array.
    return dispatch(
      receiveProviderProcedures(Object.values(_providerProcedures))
    );
  };

/**
 * ACTION CREATORS
 */

export function receivePricingGroups(pricingGroups) {
  return {
    type: RECEIVE_PRICING_GROUPS,
    pricingGroups,
  };
}

export function receiveProviders(providers, merge = false) {
  return {
    type: RECEIVE_PROVIDERS,
    providers,
    merge,
  };
}

export function receiveClientProviders(providers, merge = false) {
  return {
    type: RECEIVE_CLIENT_PROVIDERS,
    providers,
    merge,
  };
}

export function receiveProvider(provider) {
  return {
    type: RECEIVE_PROVIDER,
    provider,
  };
}

export function receiveProviderProcedures(providerProcedures) {
  return {
    type: RECEIVE_PROVIDER_PROCEDURES,
    providerProcedures,
  };
}

export function receiveProviderLoading(providerLoading) {
  return {
    type: RECEIVE_PROVIDER_LOADING,
    providerLoading,
  };
}
