import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ENVIRONMENT } from 'app/utils/constants';
import { capitalizeFirstLetter } from 'app/utils/methods';

import {
  CareAppLogo,
  DropdownBox,
  EnvironmentText,
  FormSearch,
  HeaderBox,
  Image,
  Link,
  MailOption,
  Menu,
  MenuBox,
  MenuDropdown,
  MenuItem,
  MessageBox,
  MessageListItem,
  RelativeBox,
  Spinner,
  TextInput,
  TextInputBox,
} from 'app/components/Header/styles';

export class Header extends Component {
  static propTypes = {
    conversationListOpen: PropTypes.bool.isRequired,
    conversations: PropTypes.arrayOf(PropTypes.object).isRequired,
    conversationsLoaded: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    openConversation: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  state = {
    dropdown: null,
    search: '',
  };

  handleLogout = () => this.props.logout();

  /**
   * Close the selected dropdown if it is open or no argument is given.
   * Otherwise, open it.
   *
   * @param  {string}  dropdown  the name of the dropdown to toggle
   */
  handleDropdown = (dropdown) => {
    if (!dropdown || this.state.dropdown === dropdown)
      return this.setState({ dropdown: null });

    this.setState({ dropdown });
  };

  /**
   * Close the open dropdown and open the selected conversation.
   *
   * @param  {object}  patient  the patient in the conversation
   */
  onMessageItemClick = (patient) => {
    this.handleDropdown();
    this.props.openConversation(patient);
  };

  /**
   * Iterate through conversations and increment the number of new
   * conversations for each conversation where the last message is unread.
   *
   * @return  {number}  the count of unread conversations
   */
  getNewConversationsCount = () => {
    const { user, conversations } = this.props;

    let newConversations = 0;

    conversations.forEach((convo) => {
      const { messages } = convo;

      if (!messages.length) return;

      const lastMessage = messages[messages.length - 1];

      if (!this.messageReadBy(user, lastMessage)) newConversations += 1;
    });

    return newConversations;
  };

  /**
   * Limit the number of results in the dropdown list.
   *
   * - If a search term is present, return 8.
   * - Otherwise, return 8 or the count of conversations until the last unread
   *   conversation, whichever is greater.
   */
  getMessageListLimit = () => {
    if (this.state.search.length) return 8;

    const { user, conversations } = this.props;

    const newConversationsCount = this.getNewConversationsCount();

    let count = 0;
    let unreadCount = 0;

    for (let i = 0; i < conversations.length; i++) {
      const conversation = conversations[i];

      const { messages } = conversation;

      if (!messages.length) continue;

      const message = messages[messages.length - 1];

      count += 1;

      if (!this.messageReadBy(user, message)) unreadCount += 1;

      if (count >= 8 && unreadCount >= newConversationsCount) break;
    }

    return count;
  };

  /**
   * Return an array of conversation participants who are not the current user.
   *
   * @return  {array}  an array of the conversation participants
   */
  getParticipants = (convo) => {
    const { user } = this.props;

    return convo.participants.filter(
      (participant) => participant && participant.id !== user.id
    );
  };

  /**
   * Determine whether the user has read a specific message.
   *
   * @param   {object}   user     the user to test
   * @param   {object}   message  the message to test
   *
   * @return  {boolean}           whether the message has been read by the user
   */
  messageReadBy = (user, message) =>
    user && message.readBy.indexOf(user.id) > -1;

  /**
   * Filter recent messages based on the current search term.
   *
   * @param   {object}   message  the message object to filter
   *
   * @return  {boolean}           whether there is a matching participant name
   */
  filterRecentMessages = (message) => {
    if (!this.state.search) return message;

    return message.participants.filter(
      ({ name }) =>
        name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    ).length;
  };

  /**
   * Sort recent messages so that unread messages appear first; otherwise, sort
   * by date.
   *
   * @param   {object}   a  a message to compare for sorting
   * @param   {object}   b  a message to compare for sorting
   *
   * @return  {integer}     a number representing the sort value
   */
  sortRecentMessages = (a, b) => {
    const isReadA = this.messageReadBy(this.props.user, a);
    const isReadB = this.messageReadBy(this.props.user, b);

    if (isReadA && !isReadB) return 2;
    if (isReadB && !isReadA) return -2;

    return a.id > b.id ? -1 : 1;
  };

  /**
   * Iterate through conversations and return an array of the most recent
   * message for each one, if one exists. Limit the number of results returned
   * and filter the results if a search term if present.
   *
   * @return  {array}  the array of the most recent messages for each conversation
   */
  recentMessages = () => {
    const limit = this.getMessageListLimit();

    return this.props.conversations
      .map((conversation) => {
        const { messages } = conversation;

        if (!messages.length) return null;

        const message = messages[messages.length - 1];
        const participants = this.getParticipants(conversation);
        const patient = participants.find((user) => user.patient);

        return { ...message, participants, patient };
      })
      .filter((message) => message)
      .filter(this.filterRecentMessages)
      .slice(0, limit)
      .sort(this.sortRecentMessages);
  };

  onSearch = (event) => this.setState({ search: event.target.value });

  render() {
    const newConversations = this.getNewConversationsCount();
    const recentMessages = this.recentMessages();
    const logoLinkDestination = this.props.features.includes('episodes:view')
      ? '/episodes'
      : '/users';

    return (
      <HeaderBox padRight={this.props.conversationListOpen}>
        <Link to={logoLinkDestination}>
          <Image src={CareAppLogo} />
        </Link>

        <EnvironmentText>
          {capitalizeFirstLetter(ENVIRONMENT, false)}
        </EnvironmentText>

        <MenuBox>
          <RelativeBox
            direction="row"
            title={
              this.props.conversationsLoaded ? '' : 'Loading conversations...'
            }
          >
            <MenuItem
              icon={
                this.props.conversationsLoaded ? <MailOption /> : <Spinner />
              }
              count={newConversations}
              active={this.state.dropdown === 'messages'}
              onClick={
                this.props.conversationsLoaded
                  ? () => this.handleDropdown('messages')
                  : () => {}
              }
            />

            <MenuDropdown
              open={this.state.dropdown === 'messages'}
              handleClose={this.handleDropdown}
            >
              {(this.state.search.length > 0 || recentMessages.length > 8) && (
                <TextInputBox>
                  <TextInput
                    name="search"
                    icon={<FormSearch />}
                    placeholder="Search by member name"
                    value={this.state.search}
                    onChange={this.onSearch}
                  />
                </TextInputBox>
              )}

              {recentMessages.length === 0 && this.state.search ? (
                <MessageBox>
                  Could not find any matching conversations
                </MessageBox>
              ) : (
                recentMessages.length === 0 && (
                  <MessageBox>You have no unread messages</MessageBox>
                )
              )}

              <DropdownBox>
                {recentMessages.map((message) => (
                  <MessageListItem
                    key={`${message.conversationId}-${message.id}`}
                    delimiter=","
                    participants={message.participants}
                    belongsToUser={message.author.id === this.props.user.id}
                    readByUser={this.messageReadBy(this.props.user, message)}
                    readByPatient={this.messageReadBy(message.patient, message)}
                    date={message.createdAt}
                    content={
                      message.body ||
                      `${message.attachments.length} attachment(s)`
                    }
                    onClick={() => this.onMessageItemClick(message.patient)}
                    system={message.system}
                  />
                ))}
              </DropdownBox>
            </MenuDropdown>
          </RelativeBox>

          <Menu
            label={this.props.user.email}
            items={[
              { label: 'Profile', href: '/profile' },
              { label: 'Logout', onClick: this.handleLogout },
            ]}
          />
        </MenuBox>
      </HeaderBox>
    );
  }
}

export default withRouter(Header);
