import axios from 'axios';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';

import { setInitialLoad, showGlobalError } from 'app/actions/uiActions';
import { fetchCoreUser } from 'app/actions/userActions';
import {
  connectToMessageService,
  disconnectCable,
} from 'app/actions/actioncableActions';
import { CORE_API_URL } from 'app/utils/constants';
import { unformatObject } from 'app/utils/reducerUtils';

let expirationTimeoutId;
let reauthenticationTimeoutId;
const expiredSessionError =
  'Your session has expired. Please login again with your Carrum Health credentials.';
const invalidEmailPasswordError = 'Invalid email/password combination.';
const invalidSmsMfaError = 'Invalid SMS Multi-Factor code.';
const invalidPasswordError =
  'Password reset failed. Your password should not contain personal information or match your current password.';

/**
 * Initializes a session using the passed credentials.
 *
 * @param {string} coreServiceToken token used to authenticate with the core service
 */
export function onLogin(coreServiceToken) {
  return async (dispatch) => {
    try {
      dispatch(sessionLoad(true));

      if (!coreServiceToken) return;

      const { sub: userId, exp, features } = jwtDecode(coreServiceToken);

      await dispatch(receiveLogin(userId, coreServiceToken, features));

      const response = await dispatch(fetchCoreUser(userId));
      await dispatch(receiveCurrentUser(response.data.data));
      await dispatch(connectToMessageService());

      dispatch(resetReauthentication());
      dispatch(setReauthenticateTimeout(exp));
    } catch (error) {
      dispatch(logout());
      dispatch(receiveLoginError({ message: expiredSessionError }));
    } finally {
      dispatch(setInitialLoad(false));
    }
  };
}

/**
 * Uses data stored in the browser to log in.
 */
export function loginFromSession() {
  return (dispatch) => {
    const coreServiceToken = localStorage.getItem('coreServiceToken');

    return dispatch(onLogin(coreServiceToken));
  };
}

/**
 * Returns true if the login request requires a redirect.
 */
const isRedirectRequired = (history, response) =>
  !isEmpty(history) && response.data.redirect?.includes('sms');

/**
 * Redirect the user to the mutlifactor authentication form and
 * store the current location in local storage.
 */
const redirectToMultiFactorForm = (history, user) => {
  let { pathname } = history.location;

  // Don't redirect to any routes containing /login
  if (pathname?.indexOf('/login/sms') !== -1) {
    pathname = '/';
    return;
  }

  // Store the current location in local storage to redirect.
  localStorage.setItem('redirect', pathname);

  // Redirect to the SMS code form.
  history.replace('/login/sms', user);
};

/**
 * Redirect the user to the location stored in localStorage and
 * clear the value from storage.
 */
const redirectToRememberedLocation = (history) => {
  const redirect = localStorage.getItem('redirect');

  if (!redirect) return;

  localStorage.removeItem('redirect');
  history.replace(redirect);
};

/**
 * Logs in using a user params object.
 * @param {object} user - an object with the email and password for the user.
 */
export function loginUser(user, history = {}) {
  return async (dispatch) => {
    // Clear any previous error state
    await dispatch(receiveLoginError());
    await dispatch(receiveToggleLoading());

    try {
      const response = await axios.post(`${CORE_API_URL}/login`, {
        auth: unformatObject(user),
      });

      if (isRedirectRequired(history, response)) {
        redirectToMultiFactorForm(history, user);
        return dispatch(receiveToggleLoading());
      } else if (localStorage.getItem('redirect')) {
        redirectToRememberedLocation(history);
      }

      const token = response.data.jwt;
      const { sub, features } = jwtDecode(token);

      if (!features.includes('care')) {
        return dispatch(
          receiveLoginError({ message: invalidEmailPasswordError })
        );
      }

      dispatch(receiveLogin(sub, token, features));
      dispatch(onLogin(token));
    } catch (error) {
      // If there was an error not related to an API call, show the standard warning.
      if (!error?.response) return dispatch(showGlobalError(error));

      const message = user.smsCode
        ? invalidSmsMfaError
        : invalidEmailPasswordError;

      await dispatch(receiveLoginError({ message }));
    }
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(disconnectCable());
    dispatch(resetReauthentication());
    return Promise.resolve(dispatch(receiveLogout()));
  };
}

/**
 * Disconnect from ActionCable and show the expired session warning.
 */
export function invalidateSession() {
  return (dispatch) => {
    dispatch(disconnectCable());
    dispatch(setExpiredSessionWarning(true));
  };
}

/**
 * Sets timeouts for showing an expiration warning and
 * invalidating the session at a given time.
 *
 * @param {Date} timestamp A Date object representing the time the session expires.
 */
export function setReauthenticateTimeout(timestamp) {
  return (dispatch) => {
    const expiresIn = new Date(timestamp * 1000) - new Date();
    const fifteenMin = 15 * 60000;

    reauthenticationTimeoutId = setTimeout(
      () => dispatch(toggleSessionReauthentication(true)),
      expiresIn - fifteenMin
    );
    expirationTimeoutId = setTimeout(
      () => dispatch(invalidateSession()),
      expiresIn
    );
  };
}

/**
 * Clears the reauthentication timeouts.
 */
export function resetReauthentication() {
  return (dispatch) => {
    dispatch(toggleSessionReauthentication(false));
    clearTimeout(reauthenticationTimeoutId);
    clearTimeout(expirationTimeoutId);
  };
}

/**
 * Post password reset request to the Core Service API.
 *
 * @param {string}  email     The email of user requesting password change.
 * @param {boolean} isPatient Whether requestor is a patient.
 */
export function requestPasswordReset(email, isPatient = false) {
  return async (dispatch) => {
    let requestPayload = {
      email,
      source: 'care-app',
    };
    if (isPatient) {
      requestPayload.magic_link = true;
    } else {
      requestPayload.redirect_base_url = `${window.location.origin}/`;
    }

    try {
      await axios.post(
        `${CORE_API_URL}/users/request_password_reset`,
        requestPayload
      );

      return true;
    } catch (error) {
      if (!error.response || (error.response && error.response.status >= 500))
        dispatch(showGlobalError(error));

      return false;
    }
  };
}

export function resetPassword(params) {
  return async (dispatch) => {
    try {
      await axios.post(
        `${CORE_API_URL}/users/${params.id}/password_reset`,
        unformatObject(params)
      );

      return true;
    } catch (error) {
      if (
        error === 'You do not have access to this resource.' ||
        error.message === 'You do not have access to this resource.' ||
        (error.response && error.response.status === 403)
      ) {
        dispatch(showGlobalError(invalidPasswordError));
      } else if (
        !error.response ||
        (error.response && error.response.status >= 500)
      ) {
        dispatch(showGlobalError(error));
      }

      return false;
    }
  };
}

export function sessionLoad(sessionLoading) {
  return {
    type: 'SESSION_LOAD',
    sessionLoading,
  };
}

export function receiveLogin(userId, coreServiceToken, features) {
  return {
    type: 'RECEIVE_LOGIN',
    userId,
    coreServiceToken,
    features,
  };
}

export function receiveToggleLoading() {
  return {
    type: 'TOGGLE_LOADING',
  };
}

export function receiveLoginError(error) {
  return {
    type: 'RECEIVE_LOGIN_ERROR',
    error,
  };
}

export function receiveCurrentUser(currentUser) {
  return {
    type: 'RECEIVE_CURRENT_USER',
    currentUser,
  };
}

export function toggleSessionReauthentication(status) {
  return {
    type: 'RECEIVE_REAUTHENTICATION_PROMPT_STATUS',
    status,
  };
}

export function receiveLogout() {
  return {
    type: 'RECEIVE_LOGOUT',
  };
}

export function setExpiredSessionWarning(isExpirationMessageShown) {
  return {
    type: 'RECEIVE_EXPIRED_SESSION_WARNING',
    isExpirationMessageShown,
  };
}
