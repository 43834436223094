import { fetchFromService, fetchRecursively } from 'app/actions/commonActions';
import { CORE_API_URL } from 'app/utils/constants';

export const RECEIVE_ROLLUPS = 'RECEIVE_ROLLUPS';
export const RECEIVE_ROLLUPS_ERROR = 'RECEIVE_ROLLUPS_ERROR';
export const RECEIVE_ROLLUPS_LOADING = 'RECEIVE_ROLLUPS_LOADING';
export const SET_ROLLUPS_HAS_FETCHED = 'SET_ROLLUPS_HAS_FETCHED';

/**
 * @param {string[]} rollups
 */
export const receiveRollups = (rollups, append = false) => {
  return {
    type: RECEIVE_ROLLUPS,
    rollups,
    append,
  };
};

/**
 * @param {boolean} loading
 */
export function receiveRollupsLoading(loading) {
  return {
    type: RECEIVE_ROLLUPS_LOADING,
    loading,
  };
}

/**
 * @param {boolean} hasFetched
 */
export function setHasFetchedAllRollupsAction(hasFetched) {
  return {
    type: SET_ROLLUPS_HAS_FETCHED,
    hasFetched,
  };
}

/**
 * @param {string} error
 */
export function receiveRollupError(error) {
  return {
    type: RECEIVE_ROLLUPS_ERROR,
    error,
  };
}

const getRollups = fetchFromService(`${CORE_API_URL}/rollups`);

/**
 * Fetches all available rollups (tags), and stores them in redux.
 * @returns {boolean} Returns true if fetch is successful, otherwise returns false.
 */
export function fetchRollups(params) {
  return async (dispatch) => {
    await dispatch(receiveRollupsLoading(true));
    await dispatch(fetchRecursively(getRollups, receiveRollups, params));
    await dispatch(receiveRollupsLoading(false));

    return true;
  };
}
